import React from 'react';

import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  100% {
    transform: rotate(360deg)
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

const LoaderWrapper = styled.svg.attrs({
  viewBox: '25 25 50 50',
})`
  height: ${({ size }) => size}px;
  transform-origin: center center;
  animation: ${rotate} 2s linear infinite;
`;

const Circle = styled.circle.attrs({
  cx: '50',
  cy: '50',
  r: '20',
  fill: 'none',
  strokeWidth: '3',
  strokeMiterLimit: '10',
})`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke: ${({ theme, color }) => theme.colors[color]};
  animation: ${dash} 1.5s ease-in-out infinite;
`;

export default function Loader({ size, color = 'white', style = {} }) {
  return (
    <LoaderWrapper size={size} style={style}>
      <Circle color={color} />
    </LoaderWrapper>
  );
}
